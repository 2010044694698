<route>
{
  "meta": {
    "permission": [
      "notifications.view_notification"
    ]
  }
}
</route>
<template>
  <v-container fluid>
    <dialnotification
      v-model="dialog"
      :to-edit="toEdit"
      @done="reload = true"
    />
    <v-tabs
      class="mb-2"
      centered
      color="primary"
      next-icon="fa-chevron-right"
      prev-icon="fa-chevron-left"
      show-arrows
      :icons-and-text="$vuetify.breakpoint.smAndUp"
      v-model="tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab href="#tab-active">
        <span v-if="$vuetify.breakpoint.smAndUp" class="mt-2">
          {{ $t('active') }}
        </span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp">
          fas fa-check-double
        </v-icon>
      </v-tab>
      <v-tab href="#tab-inactive">
        <span v-if="$vuetify.breakpoint.smAndUp" class="mt-2">
          {{ $t('inactive') }}
        </span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-times-circle</v-icon>
      </v-tab>
    </v-tabs>
    <i-table
      :title="$tc('settings', 1)"
      :headers="headers"
      app="notifications.notification"
      api="notifications"
      :reload.sync="reload"
      dontCreate
      :opt="{ active: tabs === 'tab-active' }"
      @click:edit="open($event)"
    >
      <template v-slot:item.email="{ item }">
        <v-col>
          <i-btn
            :title="$tc('email', 1)"
            classes="mx-1"
            outlined
            class="mx-2 mb-1"
            :color="item.email ? 'secondary' : 'red'"
            :icon="item.email ? 'fa-check' : 'fa-times'"
          />
        </v-col>
      </template>
      <template v-slot:item.push="{ item }">
        <v-col>
          <i-btn
            :title="$tc('push', 1)"
            classes="mx-1"
            outlined
            class="mx-2 mb-1"
            :color="item.push ? 'secondary' : 'red'"
            :icon="item.push ? 'fa-check' : 'fa-times'"
          />
        </v-col>
      </template>
      <template v-slot:item.users="{ item }">
        <v-col>
          <v-chip
            color="secondary"
            v-for="user in item.users"
            :key="'chips-' + user !== undefined ? user.id : ''"
            text-color="white"
          >
            <div v-for="item in users" :key="item.pk">
              {{ item.id == user ? item.username : '' }}
            </div>
          </v-chip>
        </v-col>
      </template>
      <template v-slot:item.background="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <i-btn :color="item.background" v-on="on" :title="item.background">
            </i-btn>
          </template>
        </v-tooltip>
      </template>
    </i-table>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialnotification from '../../components/notification/modal.vue'
export default {
  components: {
    dialnotification
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('section_only'),
          value: 'content_type.app_label'
        },
        {
          text: this.$t('section_sub'),
          value: 'content_type.model'
        },
        {
          text: this.$tc('color', 1),
          value: 'background'
        },
        {
          text: this.$tc('email', 1),
          value: 'email'
        },
        {
          text: this.$t('push'),
          value: 'push'
        },
        {
          text: this.$tc('user', 2),
          value: 'users'
        },
        {
          text:
            this.tabs == 'tab-active' &&
            (this.notificationBtn.delete || this.notificationBtn.edit)
              ? this.$t('action')
              : '',
          value:
            this.tabs == 'tab-active' &&
            (this.notificationBtn.delete || this.notificationBtn.edit)
              ? 'actions'
              : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    notificationBtn() {
      return {
        delete:
          this.getPermissions([`notifications.delete_notification`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([`notifications.change_notification`]) ||
          this.isAdmin
      }
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null,
      users: [],
      attributes: [],
      tabs: 'tab-active'
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    async getUsers() {
      const user = await this.$api.user.list({})
      this.users = user.data.results
    }
  },
  mounted() {
    this.getUsers()
  }
}
</script>
