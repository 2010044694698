<template>
  <i-modal
    :title="
      $t(edit ? 'editing' : 'add', {
        value: toEdit !== null ? $tc(toEdit.model, 1) : ''
      })
    "
    :value="value"
    :closed="true"
    :load="loading"
    :max-width="'1200px'"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row v-if="notification != false">
      <v-col cols="9">
        <i-rol-users
          autocomplete="off"
          outlined
          :name="$tc('roles', 2)"
          v-model="notification.users"
          :label="$tc('roles', 2)"
          :toEdit="notification"
          :api="'notifications'"
          ref="users"
        ></i-rol-users>
        <v-row>
          <v-col cols="6">
            <v-skeleton-loader
              v-if="loading"
              type="button"
              tile
            ></v-skeleton-loader>
            <v-switch
              v-show="!loading"
              v-model="notification.email"
              :label="$tc('email', 1)"
              name="email"
              color="primary"
              key="active-input"
            ></v-switch>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-if="loading"
              type="button"
              tile
            ></v-skeleton-loader>
            <v-switch
              v-show="!loading"
              v-model="notification.push"
              :label="$tc('push', 1)"
              name="push"
              color="primary"
              key="tax-input"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <span class="title grey--text font-weight-black">
          {{ $tc('color', 1) }}
        </span>
        <v-color-picker
          elevation="1"
          mode="hexa"
          canvas-height="120"
          dot-size="18"
          v-model="notification.background"
        ></v-color-picker>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      edit: false,
      notification: {
        email: false,
        push: false,
        event: '',
        background: '',
        users: []
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.notification =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  email: false,
                  push: false,
                  event: '',
                  background: '',
                  users: []
                }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          this.toEdit.pk == ''
            ? await this.$api.notifications.create({
                form: this.notification
              })
            : await this.$api.notifications.edit({
                pk: this.notification.pk,
                form: this.notification
              })
          this.$emit('done')
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('notifications', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
